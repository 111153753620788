@import 'variables';
@import 'mixins';

@import '@slidebean/ui/styles/main';

html,
body {
  height: 100%;
  min-height: 100%;
}
