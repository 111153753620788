@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

@mixin scroll-container-vertical($maxHeight: 100%) {
  max-height: $maxHeight;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@mixin scroll-container-horizontal($maxWidth: 100%) {
  max-width: $maxWidth;
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* FONTS */
@mixin font-face(
  $font-path,
  $font-family,
  $font-filename,
  $font-weight: normal,
  $font-style: normal,
  $font-stretch: normal
) {
  @font-face {
    font-family: '#{$font-family}';
    src: url('#{$font-path}#{$font-filename}.eot');
    src: url('#{$font-path}#{$font-filename}.eot?#iefix')
        format('embedded-opentype'),
      url('#{$font-path}#{$font-filename}.woff') format('woff'),
      // url('#{$font-path}#{$font-filename}.otf') format('opentype'),
      url('#{$font-path}#{$font-filename}.ttf') format('truetype'),
      url('#{$font-path}#{$font-filename}.svg##{$font-family}') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
    font-stretch: $font-stretch;
  }
}

@import '@slidebean/ui/styles/_mixins';
