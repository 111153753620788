@import '@slidebean/render/legacy/sass/_variables';
@import '@slidebean/ui/styles/_functions';
@import '@slidebean/ui/styles/_variables';

// Global settings
$enable-print-styles: false;

$input-placeholder-color: darken(theme-color('light'), 12%);
$input-disabled-bg: theme-color('light');
//$input-border-color: #EAEAEA;

// TODO: Migrate to @slidebean/ui
$tooltip-max-width: 280px;
$tooltip-color: $body-color;
$tooltip-bg: $gray-200;
$tooltip-arrow-color: $gray-200;

// Layout

$top-bar-height: 70px;
$side-bar-width-mobile: 80vw;
$side-bar-width-desktop: 240px;

// Modals
$modal-sm: 380px;

// Render Module

$slide-viewer-background: $white;
$slide-menu-font: $font-family-sans-serif;
$slide-assets-path: '/assets/render';
$slide-player-upgrade-background: $pink;
$slide-player-upgrade-color: $white;
